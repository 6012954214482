import { createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
    name: 'loaderState',
    initialState: {
        loader: false
    },
    reducers: {
        setLoading: (state, action) => {
            state.loader = action.payload;
        }
    }
})

export const {setLoading} = loaderSlice.actions;

export const LoaderReducer = loaderSlice.reducer;
