import React, { lazy, Suspense } from 'react';

const LazyPlanCreated = lazy(() => import('./PlanCreated'));

const PlanCreated = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPlanCreated {...props} />
  </Suspense>
);

export default PlanCreated;
