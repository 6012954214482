import React, { lazy, Suspense } from 'react';

const LazyInvalidComponent = lazy(() => import('./InvalidComponent'));

const InvalidComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyInvalidComponent {...props} />
  </Suspense>
);

export default InvalidComponent;
