import React, { lazy, Suspense } from 'react';

const LazyLoaderComponent = lazy(() => import('./LoaderComponent'));

const LoaderComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyLoaderComponent {...props} />
  </Suspense>
);

export default LoaderComponent;
