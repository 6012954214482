export interface Country {
    country_id: number;
    country_code: string;
    country_name: string;
    mobile_code: string;
    country_phone_format: string;
    show_instant_payment_card: number;
    identity_label: string;
    identity_document_min_length: number;
    identity_document_max_length: number;
    enable_bank: number;
}

export interface Currency {
    currency_id: number;
    display_logo: string;
    currency_code: string;
    currency_name: string;
    exchange_rate: number;
    bank_support_type: string; // Note: It is a JSON string. Adjust if necessary.
}

export enum StatusType {
    Idle = 'idle',
    Loading = 'loading',
    Succeeded = 'succeeded',
    Failed = 'failed'
}

export enum PreviewSteps {
    Step1= 'step-1',
    Step2= 'step-2'
}

export type PaymentMatrixType =  'BACKUP' | 'PRIMARY' | 'RECURRING'
export enum PaymentMatrixTypeEnum {
     BACKUP = 'BACKUP',
     PRIMARY = 'PRIMARY',
     RECURRING = 'RECURRING'
 }

export enum PaymentMethodType {
    CARD = 2,
    BANK = 1
}

export interface PaymentParams {
    payment_source_type: number,
    payment_matrix_type: PaymentMatrixType,
    contract_patient_token: string | undefined
}

